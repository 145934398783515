import * as React from 'react'

type IconButtonProps = {
  children: React.ReactNode
}

export function IconButton(props: IconButtonProps) {
  return (
    <div className='icon-button'>
      {props.children}
    </div>
  )

}