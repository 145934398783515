import React, { useState, ReactElement } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink,
  useHistory,
} from "react-router-dom";

import { IconInstagram, IconHamburger, IconX } from "../icons";

import andromacheSrc from "../images/illustration/andromache.png";
import andromacheLightbox from "../images/illustration/andromache_by_jessichenliu.png";
const andromache: GalleryImage = {
  src: andromacheSrc,
  lightboxSrc: andromacheLightbox,
  title: <span><i>Andromache Between Worlds</i> cover</span>,
  description: "HarperCollins, AD: Mark Campbell"
};

import apoPianoSrc from "../images/illustration/apo_piano.png";
import apoPianoLightbox from "../images/illustration/apo_piano_by_jessichenliu.png";
const apoPiano: GalleryImage = {
  src: apoPianoSrc,
  lightboxSrc: apoPianoLightbox,
};

import apoTaichiSrc from "../images/illustration/apo_taichi.png";
import apoTaichiLightbox from "../images/illustration/apo_taichi_by_jessichenliu.png";
const apoTaichi = {
  src: apoTaichiSrc,
  lightboxSrc: apoTaichiLightbox,
};

import apoMathSrc from "../images/illustration/apo_math.png";
import apoMathLightbox from "../images/illustration/apo_math_by_jessichenliu.png";
const apoMath: GalleryImage = { src: apoMathSrc, lightboxSrc: apoMathLightbox };

import apoKitchenSrc from "../images/illustration/apo_kitchen.png";
import apoKitchenLightbox from "../images/illustration/apo_kitchen_by_jessichenliu.png";
const apoKitchen: GalleryImage = { src: apoKitchenSrc, lightboxSrc: apoKitchenLightbox };

import thumbelinaSrc from "../images/illustration/thumbelina.png";
import thumbelinaLightbox from "../images/illustration/thumbelina_by_jessichenliu.png";
const thumbelina: GalleryImage = { src: thumbelinaSrc, lightboxSrc: thumbelinaLightbox };

import charlieSrc from "../images/illustration/charlie_chocolate_factory.png";
import charlieLightbox from "../images/illustration/charlie_chocolate_factory_by_jessichenliu.png";
const charlie: GalleryImage = { src: charlieSrc, lightboxSrc: charlieLightbox };

import fishSrc from "../images/illustration/fish.png";
import fishLightbox from "../images/illustration/fish_by_jessichenliu.png";
const fish: GalleryImage = { src: fishSrc, lightboxSrc: fishLightbox };

import liftSrc from "../images/illustration/lift.png";
import liftLightbox from "../images/illustration/lift_by_jessichenliu.png";
const lift: GalleryImage = { src: liftSrc, lightboxSrc: liftLightbox };

import witSrc from "../images/illustration/wit.png";
import witLightbox from "../images/illustration/wit_by_jessichenliu.png";
const wit: GalleryImage = { src: witSrc, lightboxSrc: witLightbox };

import sylSrc from "../images/illustration/syl.png";
import sylLightbox from "../images/illustration/syl_by_jessichenliu.png";
const syl: GalleryImage = { src: sylSrc, lightboxSrc: sylLightbox };

import shallanSrc from "../images/illustration/shallan.png";
import shallanLightbox from "../images/illustration/shallan_by_jessichenliu.png";
const shallan: GalleryImage = { src: shallanSrc, lightboxSrc: shallanLightbox };

import mongoliaSrc from "../images/illustration/mongolia.png";
import mongoliaLightbox from "../images/illustration/mongolia_by_jessichenliu.png";
const mongolia: GalleryImage = { src: mongoliaSrc, lightboxSrc: mongoliaLightbox };

import tibetSrc from "../images/illustration/tibet.png";
import tibetLightbox from "../images/illustration/tibet_by_jessichenliu.png";
const tibet: GalleryImage = { src: tibetSrc, lightboxSrc: tibetLightbox };

import witchesSrc from "../images/illustration/witches.png";
import witchesLightbox from "../images/illustration/witches_by_jessichenliu.png";
const witches: GalleryImage = { src: witchesSrc, lightboxSrc: witchesLightbox };

import bandSrc from "../images/illustration/band.png";
import bandLightbox from "../images/illustration/band_by_jessichenliu.png";
const band: GalleryImage = { src: bandSrc, lightboxSrc: bandLightbox };

import xianSrc from "../images/illustration/xian.png";
import xianLightbox from "../images/illustration/xian_by_jessichenliu.png";
const xian: GalleryImage = { src: xianSrc, lightboxSrc: xianLightbox };

import booksSrc from "../images/illustration/books.png";
import booksLightbox from "../images/illustration/books_by_jessichenliu.png";
const books: GalleryImage = { src: booksSrc, lightboxSrc: booksLightbox };

import jasnahSrc from "../images/illustration/jasnah.png";
import jasnahLightbox from "../images/illustration/jasnah_by_jessichenliu.png";
const jasnah: GalleryImage = { src: jasnahSrc, lightboxSrc: jasnahLightbox };

import violetSrc from "../images/illustration/violet.png";
import violetLightbox from "../images/illustration/violet_by_jessichenliu.png";

const violet: GalleryImage = {
  src: violetSrc,
  lightboxSrc: violetLightbox,
  title: <span><i>Violet Made of Thorns</i> front endpaper</span>,
  description: "Unieboek Het Spectrum, AD: Arienne Huisman"
};

import cyrusSrc from "../images/illustration/cyrus.png";
import cyrusLightbox from "../images/illustration/cyrus_by_jessichenliu.png";
const cyrus: GalleryImage = {
  src: cyrusSrc,
  lightboxSrc: cyrusLightbox,
  title: <span><i>Violet Made of Thorns</i> back endpaper</span>,
  description: "Unieboek Het Spectrum, AD: Arienne Huisman"
};

import fireheartSrc from "../images/illustration/fireheart.png";
import fireheartLightbox from "../images/illustration/fireheart_by_jessichenliu.png";
const fireheart: GalleryImage = {
  src: fireheartSrc,
  lightboxSrc: fireheartLightbox,
  title: <span><i>Fire Heart</i> cover</span>,
  description: "Scholastic, AD: Geraldine Dutton"
};

import vivennaSrc from "../images/illustration/vivenna.png";
import vivennaLightbox from "../images/illustration/vivenna_by_jessichenliu.png";
const vivenna: GalleryImage = { src: vivennaSrc, lightboxSrc: vivennaLightbox };

import yumiSrc from "../images/illustration/yumi.png";
import yumiLightbox from "../images/illustration/yumi_by_jessichenliu.png";
const yumi: GalleryImage = { src: yumiSrc, lightboxSrc: yumiLightbox };

import tressSrc from "../images/illustration/tress.png";
import tressLightbox from "../images/illustration/tress_by_jessichenliu.png";
const tress: GalleryImage = { src: tressSrc, lightboxSrc: tressLightbox };

import kiraPlaneSrc from "../images/illustration/kira_plane.png";
import kiraPlaneLightbox from "../images/illustration/kira_plane_by_jessichenliu.png";
const kiraPlane: GalleryImage = {
  src: kiraPlaneSrc,
  lightboxSrc: kiraPlaneLightbox ,
  title: <span><i>Kira and Dad in Vietnam</i></span>,
  description: "Teacher Created Materials, AD: Fabiola Sepulveda"
}

import kiraBikeSrc from "../images/illustration/kira_bike.png";
import kiraBikeLightbox from "../images/illustration/kira_bike_by_jessichenliu.png";
const kiraBike: GalleryImage = {
  src: kiraBikeSrc,
  lightboxSrc: kiraBikeLightbox,
  title: <span><i>Kira and Dad in Vietnam</i></span>,
  description: "Teacher Created Materials, AD: Fabiola Sepulveda"
};

import macabeesSrc from "../images/illustration/macabees.png";
import macabeesLightbox from "../images/illustration/macabees_by_jessichenliu.png";
const macabees: GalleryImage = {
  src: macabeesSrc,
  lightboxSrc: macabeesLightbox,
  title: <span><i>Eight Bright Nights</i> pages 5-6</span>,
  description: "Hachai Publishing, AD: Devorah Rosenfeld"
};

import latkesSrc from "../images/illustration/latkes.png";
import latkesLightbox from "../images/illustration/latkes_by_jessichenliu.png";
const latkes: GalleryImage = {
  src: latkesSrc,
  lightboxSrc: latkesLightbox,
  title: <span><i>Eight Bright Nights</i> pages 11-12</span>,
  description: "Hachai Publishing, AD: Devorah Rosenfeld"
};

import goatSrc from "../images/illustration/goat.png";
import goatLightbox from "../images/illustration/goat_by_jessichenliu.png";
const goat: GalleryImage = { src: goatSrc, lightboxSrc: goatLightbox };

import ratSrc from "../images/illustration/rat.png";
import ratLightbox from "../images/illustration/rat_by_jessichenliu.png";
const rat: GalleryImage = { src: ratSrc, lightboxSrc: ratLightbox };

import dragonSrc from "../images/illustration/dragon.png";
import dragonLightbox from "../images/illustration/dragon_by_jessichenliu.png";
const dragon: GalleryImage = { src: dragonSrc, lightboxSrc: dragonLightbox };

import roosterSrc from "../images/illustration/rooster.png";
import roosterLightbox from "../images/illustration/rooster_by_jessichenliu.png";
const rooster: GalleryImage = { src: roosterSrc, lightboxSrc: roosterLightbox };

import vinSrc from "../images/illustration/vin.png";
import vinLightbox from "../images/illustration/vin_by_jessichenliu.png";
const vin: GalleryImage = { src: vinSrc, lightboxSrc: vinLightbox };

import wickedSrc from "../images/illustration/wicked.png";
import wickedLightbox from "../images/illustration/wicked_by_jessichenliu.png";
const wicked: GalleryImage = { src: wickedSrc, lightboxSrc: wickedLightbox };

import forestrunSrc from "../images/illustration/forestrun.png";
import forestrunLightbox from "../images/illustration/forestrun_by_jessichenliu.png";
const forestrun: GalleryImage = { src: forestrunSrc, lightboxSrc: forestrunLightbox };

import shadesofmagic1Src from "../images/illustration/shadesofmagic1.png";
import shadesofmagic1Lightbox from "../images/illustration/shadesofmagic1_by_jessichenliu.png";
const shadesofmagic1: GalleryImage = {
  src: shadesofmagic1Src,
  lightboxSrc: shadesofmagic1Lightbox,
  title: <span><i>A Darker Shade of Magic</i> tip-in</span>,
  description: "Owlcrate, AD: Sho Ling He Feng"
};

import shadesofmagic2Src from "../images/illustration/shadesofmagic2.png";
import shadesofmagic2Lightbox from "../images/illustration/shadesofmagic2_by_jessichenliu.png";
const shadesofmagic2: GalleryImage = {
  src: shadesofmagic2Src,
  lightboxSrc: shadesofmagic2Lightbox,
  title: <span><i>A Gathering of Shadows</i> tip-in</span>,
  description: "Owlcrate, AD: Sho Ling He Feng"
};

import shadesofmagic3Src from "../images/illustration/shadesofmagic3.png";
import shadesofmagic3Lightbox from "../images/illustration/shadesofmagic3_by_jessichenliu.png";
const shadesofmagic3: GalleryImage = {
  src: shadesofmagic3Src,
  lightboxSrc: shadesofmagic3Lightbox,
  title: <span><i>A Conjuring of Light</i> tip-in</span>,
  description: "Owlcrate, AD: Sho Ling He Feng"
};

import smallfortunes1Src from "../images/illustration/smallfortunes1.png";
import smallfortunes1Lightbox from "../images/illustration/smallfortunes1_by_jessichenliu.png";
const smallfortunes1: GalleryImage = {
  src: smallfortunes1Src,
  lightboxSrc: smallfortunes1Lightbox,
  title: <span><i>Teller of Small Fortunes</i> endpaper</span>,
  description: "Owlcrate, AD: Teresa Chen"
};

import maskofmirrors1Src from "../images/illustration/maskofmirrors1.png";
import maskofmirrors1Lightbox from "../images/illustration/maskofmirrors1_by_jessichenliu.png";
const maskofmirrors1: GalleryImage = {
  src: maskofmirrors1Src,
  lightboxSrc: maskofmirrors1Lightbox,
  title: <span><i>Mask of Mirrors</i> interior art</span>,
  description: "The Broken Binding, AD: Petrik Leo"
};

import maskofmirrors2Src from "../images/illustration/maskofmirrors2.png";
import maskofmirrors2Lightbox from "../images/illustration/maskofmirrors2_by_jessichenliu.png";
const maskofmirrors2: GalleryImage = {
  src: maskofmirrors2Src,
  lightboxSrc: maskofmirrors2Lightbox,
  title: <span><i>Mask of Mirrors</i> interior art</span>,
  description: "The Broken Binding, AD: Petrik Leo"
};

import maskofmirrors3Src from "../images/illustration/maskofmirrors3.png";
import maskofmirrors3Lightbox from "../images/illustration/maskofmirrors3_by_jessichenliu.png";
const maskofmirrors3: GalleryImage = {
  src: maskofmirrors3Src,
  lightboxSrc: maskofmirrors3Lightbox,
  title: <span><i>Mask of Mirrors</i> interior art</span>,
  description: "The Broken Binding, AD: Petrik Leo"
};

import sixrealms1Src from "../images/illustration/sixrealms1.png";
import sixrealms1Lightbox from "../images/illustration/sixrealms1_by_jessichenliu.png";
const sixrealms1: GalleryImage = {
  src: sixrealms1Src,
  lightboxSrc: sixrealms1Lightbox,
  title: <span><i>Song of the Six Realms</i> front endpaper</span>,
  description: "Owlcrate, AD: Sho Ling He Feng"
};

import sixrealms2Src from "../images/illustration/sixrealms2.png";
import sixrealms2Lightbox from "../images/illustration/sixrealms2_by_jessichenliu.png";
const sixrealms2: GalleryImage = {
  src: sixrealms2Src,
  lightboxSrc: sixrealms2Lightbox,
  title: <span><i>Song of the Six Realms</i> back endpaper</span>,
  description: "Owlcrate, AD: Sho Ling He Feng"
};

import dawnofthefirebirdSrc from "../images/illustration/dawnofthefirebird.png";
import dawnofthefirebirdLightbox from "../images/illustration/dawnofthefirebird_by_jessichenliu.png";
const dawnofthefirebird: GalleryImage = {
  src: dawnofthefirebirdSrc,
  lightboxSrc: dawnofthefirebirdLightbox,
  title: <span><i>Dawn of the Firebird</i> marketing</span>,
  description: "AD: Sarah Mughal Rana"
};

import prisonerSrc from "../images/illustration/prisoner.png";
import prisonerLightbox from "../images/illustration/prisoner_by_jessichenliu.png";
const prisoner: GalleryImage = {
  src: prisonerSrc,
  lightboxSrc: prisonerLightbox,
  title: <span><i>Cosmere RPG, Stonewalkers Adventure</i></span>,
  description: "AD: Katie Payne"
};

import processSrc from "../images/illustration/process.png";
import processLightbox from "../images/illustration/process_by_jessichenliu.png";
const process: GalleryImage = { src: processSrc, lightboxSrc: processLightbox };

import jessica from "../images/about.png";
import { Lightbox } from "./Lightbox";
import { GalleryImage, GalleryRow } from "./GalleryRow";
import { IconButton } from "./IconButton";
import { GalleryGrid } from "./GalleryGrid";

export const ResizeObserver = (window as any).ResizeObserver;

export const MAX_WIDTH = 1440;
export const MOBILE_BREAK = 720;

const newGalleryRows: GalleryImage[][] = [
  [dawnofthefirebird],
  [rooster, rat, goat],
  [smallfortunes1],
  [shadesofmagic1, shadesofmagic2, shadesofmagic3],
  [forestrun],
  [maskofmirrors1, maskofmirrors2, maskofmirrors3],
];

const wotcGalleryRows: GalleryImage[][] = [
  [dawnofthefirebird],
  [rooster, rat, goat],
  [smallfortunes1],
  [shadesofmagic1, shadesofmagic2, shadesofmagic3],
  [forestrun],
  [maskofmirrors1, maskofmirrors2, maskofmirrors3],
  [process],
];

const illustrationGalleryRows: GalleryImage[][] = [
  [goat, rooster, rat],
  [dragon, yumi],
  [vin, forestrun],
  [sixrealms1, sixrealms2],
];

const kidlitGalleryRows: GalleryImage[][] = [
  [thumbelina, andromache, shallan],
  [mongolia, tibet],
  [kiraPlane, kiraBike],
  [apoTaichi, apoKitchen, apoMath],
  [macabees, latkes],
  // [witches, books],
];

function About() {
  return (
    <>
      <div className="two-col-reverse">
        <div>
          <p>
            Hi, I'm Jessica! I'm a freelance illustrator based in San Francisco. 
            My work features vibrant colors, dynamic lighting and composition, and expressive characters.
            I illustrate in a variety of styles, from Fantasy and YA to Middle Grade and Kidlit.
          </p>
          <p>&nbsp;</p>
          <p>
            Some of my past clients include Harper Collins, Scholastic, Owlcrate, and Brotherwise Games.
          </p>
          <p>&nbsp;</p>
          <p>You can reach me at:</p>
          <p>hello@jessichenliu.com</p>
        </div>
        <img className="width-30" src={jessica} />
      </div>
    </>
  );
}

function Nav(props: { className?: string }) {
  return (
    <nav className={props.className}>
      <ul>
        <li>
          <NavLink to="/" exact>Fantasy & YA</NavLink>
        </li>
        <li>
          <NavLink to="/kidlit">Middle Grade & Kidlit</NavLink>
        </li>
        <li>
          <NavLink to="/about">About</NavLink>
        </li>
        <li>
          <a href="https://instagram.com/jessichenliu" target="_blank">
            <IconButton>
              <IconInstagram />
            </IconButton>
          </a>
        </li>
      </ul>
    </nav>
  );
}

function DesktopHeader(props: {footer?: boolean}) {
  return (
    <div className={`desktop-header ${props.footer && "footer-nav"}`}>
      {props.footer ? <span/> :(
        <NavLink to="/">
          <h1>Jessica Liu</h1>
        </NavLink>
      )}
      <Nav />
    </div>
  );
}

function MobileHeader() {
  const [navShown, setNavShown] = useState(false);

  let history = useHistory();
  history.listen(() => {
    setNavShown(false);
  });

  return (
    <div className="mobile-header">
      <h1>Jessica Liu</h1>
      <div onMouseDown={() => setNavShown(!navShown)}>
      <IconButton>
        <IconHamburger />
      </IconButton>
      </div>
      {navShown && (
        <div className="mobile-nav">
          <div className="x" onMouseDown={() => setNavShown(false)}>
            <IconButton>
              <IconX />
            </IconButton>
          </div>
          <Nav />
        </div>
      )}
    </div>
  );
}

class App extends React.Component {
  render() {
    return (
      <div className="page">
        <Router>
          <DesktopHeader />
          <MobileHeader />
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/kidlit">
              <GalleryGrid key="kidlit" galleryRows={kidlitGalleryRows} />
              <DesktopHeader footer={true} />
            </Route>
            <Route path="/new">
              <div className="new-disclaimer">
              <strong>New works for AD review</strong> <br/> (includes unreleased, non-NDA material)
              </div>
              <GalleryGrid key="new" galleryRows={newGalleryRows} />
              <DesktopHeader footer={true} />
            </Route>
            <Route path="/wizards">
              <div className="new-disclaimer">
              <strong>New works for WotC review</strong> <br/> (includes unreleased, non-NDA material)
              </div>
              <GalleryGrid key="wizards" galleryRows={wotcGalleryRows} />
              <DesktopHeader footer={true} />
            </Route>
            <Route path="/">
              <GalleryGrid key="fantasy" galleryRows={illustrationGalleryRows} />
              <DesktopHeader footer={true} />
            </Route>
          </Switch>
        </Router>
        <footer className="page-footer">
          &copy; {new Date().getFullYear()} Jessica Liu
        </footer>
      </div>
    );
  }
}

export default App;
