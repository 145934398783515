import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { IconX, IconPrev, IconNext } from "../icons";
import { IconButton } from "./IconButton";
import { GalleryImage } from "./GalleryRow";

export function Lightbox(props: {
  items: GalleryImage[]
  initialIndex: number
  closeLightbox: () => void;
}) {
  const [index, setIndex] = useState(props.initialIndex);
  const prev = () => setIndex(Math.max(0, index - 1));
  const next = () => setIndex(Math.min(props.items.length - 1, index + 1));

  const swipeHandlers = useSwipeable({
    onSwipedRight: prev,
    onSwipedLeft: next,
  });

  const stopPropagation = (e: React.MouseEvent) => e.stopPropagation();

  const nextClicked = (e: React.MouseEvent) => {
    next();
    e.stopPropagation();
  };

  const prevClicked = (e: React.MouseEvent) => {
    prev();
    e.stopPropagation();
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode == 37) {
      prev();
    }
    if (e.keyCode == 39) {
      next();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  });

  return (
    <div className="lightbox" {...swipeHandlers} onClick={props.closeLightbox}>
      <div className="lightbox-x" onMouseDown={props.closeLightbox}>
        <IconButton>
          <IconX />
        </IconButton>
      </div>
      <div
        className={`lightbox-prev ${index == 0 ? "hidden" : ""}`}
        onClick={prevClicked}
      >
        <IconButton>
          <IconPrev />
        </IconButton>
      </div>
      <img
        className="lightbox-img"
        src={props.items[index].lightboxSrc}
        onClick={stopPropagation} />
      <div
        className={`lightbox-next ${index == props.items.length - 1 ? "hidden" : ""}`}
        onClick={nextClicked}
      >
        <IconButton>
          <IconNext />
        </IconButton>
      </div>
    </div>
  );
}
